import { Injectable } from '@angular/core';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { SourceLocationEndpointsConstant } from '@pages/distributor/constant/source-location.endpoints.constant';
import { SourceLocationProps } from '@pages/distributor/enums/source-location-mapping.enum';

@Injectable({
  providedIn: 'root'
})
export class SourceLocationService {
  constructor(private _dropdownService: DropdownService) {}

  getDropdownList() {
    return this._dropdownService.getDropdownTranslationList(
      SourceLocationEndpointsConstant.config.LIST,
      { 
        valueProp: SourceLocationProps.SOURCE_LOCATION_CODE, 
        labelProp: SourceLocationProps.SOURCE_LOCATION_CODE,
        labelExtraProp: SourceLocationProps.SOURCE_LOCATION_NAME
      },
      { resultWithinProp: ['result', 'allSourceLocations'] }
    );
  }
}
